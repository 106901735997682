import React from "react";
import { Helmet } from "react-helmet";

interface Props {
    lang: string;
}

const HtmlLang: React.SFC<Props> = ({ lang }) => (
    <Helmet
        htmlAttributes={{
            lang,
        }}
    />
);

export default HtmlLang;
