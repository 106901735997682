import React, { useContext } from "react";
import styled from "styled-components";
import { ASSETS_URL, WIDTH } from "../../config";
import mediaQueries from "../../helpers/mediaQueries";
import { makeStoreBadges } from "../../helpers/utils";
import { Content } from "../AppRoot";
import MarkdownRenderer from "../common/MarkdownRenderer";
import Slider from "./Slider";
import StoreBadges from "./StoreBadges";

const ProductPage: React.FC = () => {
    const {
        storeBadges,
        productPage: {
            introTitle,
            introSubtitle,
            introBackgroundImage,
            mainAboutTitle,
            mainAboutText,
            mainAbilitiesTitle,
            mainAbilitiesText,
            mainFeaturesTitle,
            slides,
        },
    } = useContext(Content);

    return (
        <div>
            <IntroSection
                urlImg={`${ASSETS_URL}/images/${introBackgroundImage}`}
            >
                <IntroBackground>
                    <TextContainer>
                        <IntroTitle>{introTitle}</IntroTitle>
                        <IntroSubTitle>{introSubtitle}</IntroSubTitle>
                        <StoreBadgesContainer>
                            <StoreBadges
                                storeBadges={makeStoreBadges(
                                    "en",
                                    storeBadges,
                                    {
                                        iosAppId: "1488277219",
                                        androidAppId:
                                            "com.vitotechnology.Ephemeris",
                                        huaweiAppId: "C104233997",
                                    },
                                    "btn",
                                    "intro",
                                )}
                            />
                        </StoreBadgesContainer>
                    </TextContainer>
                </IntroBackground>
            </IntroSection>
            <MainSection>
                <SmallContainer>
                    <MainTitle>{mainAboutTitle}</MainTitle>
                    <MainAboutText>{mainAboutText}</MainAboutText>
                    <MainTitle>{mainAbilitiesTitle}</MainTitle>
                    <MarkdownRenderer source={mainAbilitiesText} />
                    <MainTitle>{mainFeaturesTitle}</MainTitle>
                    <Slider slides={slides} delaySec={3} />
                    <MainDownload>
                        <StoreBadgesContainer>
                            <StoreBadges
                                storeBadges={makeStoreBadges(
                                    "en",
                                    storeBadges,
                                    {
                                        iosAppId: "1488277219",
                                        androidAppId:
                                            "com.vitotechnology.Ephemeris",
                                        huaweiAppId: "C104233997",
                                    },
                                    "btn",
                                    "main",
                                )}
                            />
                        </StoreBadgesContainer>
                    </MainDownload>
                </SmallContainer>
            </MainSection>
        </div>
    );
};

interface IntroWrapperProps {
    urlImg: string;
}
const IntroSection = styled.div<IntroWrapperProps>`
    height: 100vh;
    background: ${({ urlImg }) => `url(${urlImg}) no-repeat`};
    background-size: cover;
    background-position: center;
`;

const IntroBackground = styled.div`
    background-color: rgb(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const TextContainer = styled.div`
    color: #fff;
    text-align: center;
    padding: 0 16px;
    max-width: 550px;
`;

const IntroTitle = styled.h1`
    margin: 0 0 10px 0;
    text-transform: uppercase;
    font-weight: 600;
    font-size: 3rem;
    line-height: 2.5rem;

    ${mediaQueries.tablet(`
       font-size: 4.875rem;
       margin: 0 0 26px 0;
   `)}
`;

const IntroSubTitle = styled.p`
    margin: 0 0 100px 0;
    font-size: 1.25rem;
`;

const StoreBadgesContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    grid-column: 1 / span 12;
    grid-row: 1 / span 1;
    width: 100%;
`;
const MainSection = styled.div`
    padding: 20px 0;
`;
const SmallContainer = styled.div`
    width: 100%;
    max-width: 800px;
    margin: 0 auto;
    padding: 0 15px;
    ${mediaQueries.tablet(`max-width: ${WIDTH.tablet}px;`)}
    ${mediaQueries.laptop(`max-width: 800px;`)}
`;
const MainTitle = styled.h2`
    text-align: center;
    font-size: 1.75rem;
    text-transform: uppercase;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.primary};
    &:first-child {
        margin: 0px 0 28px;
    }
    margin: 80px 0 28px;
    ${mediaQueries.laptop(`
       font-size: 2.625rem;
       &:first-child {
        margin: 30px 0 28px;
    }
   `)}
`;
const MainAboutText = styled.div`
    font-size: 1.125rem;
    color: ${({ theme }) => theme.colors.primary};
    margin: 0 0 24px 0;
    line-height: 150%;
`;
const MainDownload = styled.div`
    margin: 40px 0 10px;
`;
export default ProductPage;
