import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";

interface ContainerProps {
    rows?: number;
}

const Container = styled.div<ContainerProps>`
    padding-right: 1rem;
    padding-left: 1rem;
    margin-right: auto;
    margin-left: auto;
    width: 100%;
    height: 100%;
    display: grid;

    grid-template-columns: repeat(12, [col] 1fr);
    grid-template-rows: ${({ rows = 12 }) => `repeat(${rows}, auto)`};
    grid-gap: 1rem;

    ${mediaQueries.tablet(`max-width: 720px;`)}
    ${mediaQueries.laptop(`max-width: 960px;`)}
    ${mediaQueries.desktop(`max-width: 1140px;`)}
`;

export default Container;
