import { DefaultTheme } from "styled-components";

// Constant for media queries:
export const WIDTH = {
    // smallPhone: 320,
    mediumPhone: 365,
    // largePhone: 425,
    // landscapePhone: 576,
    tablet: 600,
    laptop: 900,
    desktop: 1200,
};

export const THUMBNAILS: string[] = [
    "135x240.jpg",
    "180x320.jpg",
    "270x480.jpg",
    "360x640.jpg",
    "450x800.jpg",
];

export const theme: DefaultTheme = {
    name: "theme",
    colors: {
        background: "#000202",
        backgroundSecondary: "#583918",
        blueSky: "#0391DE",
        accent: "#fe2b39",
        gradient: "#ff7139",
        primary: "#E5E5E5",
        placeholder: "#949dc6",
        secondary: "#bbb",
    },
};

const err = (errMsg: string) => {
    throw new Error(errMsg);
};
export const PORT = process.env.PORT || 3000;
export const ASSETS_URL =
    process.env.REACT_APP_ASSETS_URL ||
    err("REACT_APP_ASSETS_URL is required env var.");
export const DOMAIN_URL =
    process.env.REACT_APP_DOMAIN_URL ||
    err("REACT_APP_DOMAIN_URL is required env var.");
export const MAIN_TITLE =
    process.env.REACT_APP_MAIN_TITLE ||
    err("REACT_APP_MAIN_TITLE is required env var.");
export const APPLE_APP_ID =
    process.env.REACT_APP_APPLE_APP_ID ||
    err("REACT_APP_APPLE_APP_ID is required env var.");
export const GOOGLE_PLAY_APP_ID =
    process.env.REACT_APP_GOOGLE_PLAY_APP_ID ||
    err("REACT_APP_GOOGLE_PLAY_APP_ID is required env var.");
export const FACEBOOK_APP_ID =
    process.env.REACT_APP_FACEBOOK_APP_ID ||
    err("REACT_APP_FACEBOOK_APP_ID is required env var.");
export const GOOGLE_TAG_CONTAINER_ID =
    process.env.REACT_APP_GOOGLE_TAG_CONTAINER_ID ||
    err("REACT_APP_GOOGLE_TAG_CONTAINER_ID is required env var.");
