import React from "react";
import Markdown from "react-markdown";
import { Link } from "react-router-dom";

interface MarkdownLinkProps {
    href: string;
}

const MarkdownLink: React.FC<MarkdownLinkProps> = ({ href, children }) => {
    let path = "";
    try {
        const { hostname, pathname } = new URL(href);
        if (hostname !== "starwalk.space") {
            return <a href={href}>{children}</a>;
        }
        path = pathname;
    } catch {
        path = href;
    }
    const to = path;

    // TODO: check links with search params from markdown source
    return <Link to={({ search }) => `${to}${search}`}>{children}</Link>;
};

interface Props {
    source: string;
    className?: string;
}

const MarkdownRenderer: React.FC<Props> = ({ source, className }) => {
    const renderers = {
        link: MarkdownLink,
    };
    return (
        <Markdown
            className={className}
            source={source}
            skipHtml={true}
            transformLinkUri={uri => {
                if (uri && uri.startsWith("self:")) {
                    return "#";
                }
                return Markdown.uriTransformer(uri);
            }}
            renderers={renderers}
        />
    );
};
export default MarkdownRenderer;
