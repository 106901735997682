import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { Normalize } from "styled-normalize";

// Data:
import { content } from "../content";
import { GOOGLE_TAG_CONTAINER_ID, FACEBOOK_APP_ID } from "../config";

// Components:
import Footer from "./layout/Footer";
import Main from "./layout/Main";
import GlobalStyles from "./GlobalStyles";
import NotFound from "./NotFound";
import HtmlFonts from "./helmet/HtmlFonts";
import HtmlTitle from "./helmet/HtmlTitle";
import HtmlInit from "./helmet/HtmlInit";
import HtmlGTag from "./helmet/HtmlGTag";
import MetaTags from "./MetaTags";
import HtmlFacebookAppId from "./helmet/HtmlFacebookAppId";

// Types & Interfaces:
import { ThemeProvider } from "styled-components";
import { theme } from "../config";
import ProductPage from "./main/ProductPage";

// const langParam = `/:lang(${Object.keys(content).join("|")})`;

export const Content = React.createContext(content);

// const setCookie = (ver: Version) => {
//     try {
//         const date = new Date(Date.now() + 90 * 24 * 60 * 60 * 1000);
//         document.cookie = `vitover=ver${ver}; expires=${date.toUTCString()}; path=/`;
//     } catch (e) {}
// };

// type Version = "1" | "2";

// const getVersion = (param: string | null): Version => {
//     switch (param) {
//         case "2":
//             return param;
//         default:
//             return "1";
//     }
// };

const AppRoot: React.SFC = () => {
    // const { search } = useLocation();
    // const params = new URLSearchParams(search);

    // const ver = getVersion(params.get("ver"));

    // setCookie(ver);

    return (
        <ThemeProvider theme={theme}>
            <>
                <HtmlInit />
                <HtmlFonts />
                <HtmlGTag gTag={GOOGLE_TAG_CONTAINER_ID} />
                <HtmlFacebookAppId value={FACEBOOK_APP_ID} />
                <Normalize />
                <GlobalStyles />
                <Switch>
                    <Route
                        sensitive={true}
                        path="/"
                        render={() => (
                            <Content.Provider value={content}>
                                <MetaTags />
                                <Main>
                                    <ProductPage />
                                </Main>
                                <Footer />
                            </Content.Provider>
                        )}
                    />
                    <Redirect exact={true} from="/" to="/en" />
                    <Route
                        exact={true}
                        render={() => (
                            <Content.Provider value={content}>
                                <HtmlTitle value={`404 - Page Not Found`} />
                                <Main>
                                    <NotFound />
                                </Main>
                                <Footer />
                            </Content.Provider>
                        )}
                    />
                </Switch>
            </>
        </ThemeProvider>
    );
};

export default AppRoot;
