import { WIDTH } from "../config";

const generateMediaQueries = (width: number) => (cssStyles: string) => {
    return `
        @media (min-width: ${width}px) {
           ${cssStyles}
        }
    `;
};

export const mediaQueries = {
    mediumPhone: generateMediaQueries(WIDTH.mediumPhone),
    landscapePhone: (cssStyles: string) =>
        `
        @media only screen and (max-height: 414px) and (min-width: 568px) {
           ${cssStyles}
        }
    `,
    tablet: generateMediaQueries(WIDTH.tablet),
    laptop: generateMediaQueries(WIDTH.laptop),
    desktop: generateMediaQueries(WIDTH.desktop),
};

export default mediaQueries;
