import React from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";
import ImageLoader from "./ImageLoader";

export interface StoreBadge {
    storeLink: string;
    storeImage: string;
    storeImageAlt: string;
    id: string;
}

interface Props {
    storeBadges: StoreBadge[];
}

const StoreBadges: React.SFC<Props> = ({ storeBadges }) => {
    return (
        <BadgesLayout>
            {storeBadges.map(({ id, storeLink, storeImageAlt, storeImage }) => (
                <Link id={id} key={storeLink} href={storeLink} target="blank">
                    <ImageLoader
                        aspectRatio="127:40"
                        src={storeImage}
                        alt={storeImageAlt}
                    />
                </Link>
            ))}
        </BadgesLayout>
    );
};

// Styles for Styled Components:
const BadgesLayout = styled.div`
    flex-wrap: wrap;
    justify-content: center;
    display: flex;
    position: relative;
    margin: 0 -0.5rem;
`;

const Link = styled.a`
    display: flex;
    margin: 0.5rem;
    text-decoration: none;
    width: calc(50% - 1rem);
    max-width: 200px;
    min-width: 130px;

    ${mediaQueries.laptop(`
        transition: transform 0.25s ease-in-out;
        &:hover { transform: scale(1.1); }
    `)};
`;

export default StoreBadges;
