import React, { useReducer, useRef, useEffect, useCallback } from "react";
import styled from "styled-components";

import mediaQueries from "../../helpers/mediaQueries";
import { ANGLE_RIGHT, ANGLE_LEFT } from "../../config/icons";

import Slide from "./Slide";

import Icon from "../svg/Icon";
import { FeaturesSlides } from "../../content";
import ImageLoader from "./ImageLoader";
import { ASSETS_URL } from "../../config";

interface Props {
    slides: FeaturesSlides[];
    delaySec: number;
    button?: string;
}

type SliderReduceAction =
    | { type: "set"; index: number }
    | { type: "right" }
    | { type: "left" }
    | { type: "autoSwitch" };

type SliderReducer = (
    currentIndex: number,
    action: SliderReduceAction,
) => number;

const Slider: React.FC<Props> = ({ slides, delaySec }) => {
    const intervalRef = useRef<any>(undefined);
    const timeOutRef = useRef<any>(undefined);

    const sliderAspectRatio = "16:9";
    const [width, height] = sliderAspectRatio
        .split(":")
        .map(size => Number(size));
    const padding = (height / width) * 100;

    const setAutoPlay = useCallback(() => {
        intervalRef.current = setInterval(
            () => dispatch({ type: "autoSwitch" }),
            delaySec * 1000,
        );
    }, [delaySec]);

    const delayAutoPlay = () => {
        clearInterval(intervalRef.current);
        clearTimeout(timeOutRef.current);
        timeOutRef.current = setTimeout(setAutoPlay, 10000);
    };

    const [activeIndex, dispatch] = useReducer<SliderReducer, number>(
        (currentIndex, action) => {
            switch (action.type) {
                case "right": {
                    delayAutoPlay();
                    return currentIndex === slides.length - 1
                        ? 0
                        : currentIndex + 1;
                }
                case "left": {
                    delayAutoPlay();
                    return currentIndex === 0
                        ? slides.length - 1
                        : currentIndex - 1;
                }

                case "set": {
                    delayAutoPlay();
                    return action.index;
                }
                case "autoSwitch": {
                    return currentIndex === slides.length - 1
                        ? 0
                        : currentIndex + 1;
                }
                default:
                    throw new Error("Invalid action type.");
            }
        },
        0,
        () => 0,
    );

    useEffect(() => {
        setAutoPlay();
        return () => {
            clearInterval(intervalRef.current);
            clearTimeout(timeOutRef.current);
        };
    }, [setAutoPlay]);
    return (
        <SliderContainer>
            <Wrapper>
                <Slides padding={padding}>
                    {slides.map(({ id, name, sourceUrl }, index) => (
                        <Slide
                            key={`${id}${index}`}
                            isActive={index === activeIndex}
                            onRightHandler={() => dispatch({ type: "right" })}
                            onLeftHandler={() => dispatch({ type: "left" })}
                        >
                            <ImageLoader
                                aspectRatio="1920:1080"
                                src={`${ASSETS_URL}/images/features/${sourceUrl}`}
                                alt={name}
                            />
                        </Slide>
                    ))}

                    <LeftBtn onClick={() => dispatch({ type: "left" })}>
                        <Btn path={ANGLE_LEFT} />
                    </LeftBtn>
                    <RightBtn onClick={() => dispatch({ type: "right" })}>
                        <Btn path={ANGLE_RIGHT} />
                    </RightBtn>
                </Slides>
                <Dots>
                    {slides.map(({ id }, index) => (
                        <Dot
                            key={`${id}${index}`}
                            isActive={index === activeIndex}
                            onClick={() => dispatch({ type: "set", index })}
                        />
                    ))}
                </Dots>
                <Descriptions>
                    {slides.map(({ id, description }, index) => (
                        <Description
                            key={`${id}${index}`}
                            isActive={index === activeIndex}
                        >
                            <Text>{description}</Text>
                        </Description>
                    ))}
                </Descriptions>
            </Wrapper>
        </SliderContainer>
    );
};

const SliderContainer = styled.div`
    width: 100%;
    border-radius: 10px;
`;
const Wrapper = styled.div`
    margin: 0 0 20px;
`;
interface SlidesProps {
    padding: number;
}
const Slides = styled.div<SlidesProps>`
    width: 100%;
    position: relative;
    border-radius: 10px;
    transform: translateZ(0);
    mask-image: -webkit-radial-gradient(circle, white, black);
    height: 0;
    padding-bottom: ${({ padding }) => `${padding}%`};
    overflow: hidden;
    background: #fff;
    user-select: none;
    touch-action: pan-y pinch-zoom;
`;
interface StatusProps {
    isActive: boolean;
}

const Descriptions = styled.div`
    text-align: center;
    max-width: 100%;
    position: relative;
    transition: all 0.4s;
    height: 75px;
`;

const Description = styled.div<StatusProps>`
    text-align: center;
    left: 50%;
    width: 100%;
    transform: translate(-50%, 0);
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    z-index: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: all 0.4s;
    position: absolute;
`;

const Text = styled.div`
    font-size: 1rem;
    text-align: center;
    line-height: 24px;
    color: ${({ theme }) => theme.colors.primary};
`;

const Dots = styled.div`
    display: flex;
    justify-content: center;
    margin: 12px 0 16px 0;
`;

const Dot = styled.span<StatusProps>`
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: ${({ isActive, theme }) =>
        isActive ? `${theme.colors.primary}` : `${theme.colors.background}`};
    border: 1px solid ${({ theme }) => theme.colors.primary};
    margin: 4px;
    ${mediaQueries.laptop(`
        width: 12px;
        height: 12px;
        margin: 6px;
    `)}
`;

const RightBtn = styled.div`
    position: absolute;
    right: 0;
    height: 100%;
    width: 8%;
    display: none;
    transition: 0.5s;
    ${mediaQueries.laptop(`display: flex;
    justify-content: center;`)}
    &:hover {
        background: rgba(14, 14, 14, 0.3);
    }
`;

const LeftBtn = styled.div`
    position: absolute;
    left: 0px;
    height: 100%;
    width: 8%;
    display: none;
    transition: 0.5s;
    ${mediaQueries.laptop(`display: flex;
    justify-content: center;`)}
    &:hover {
        background: rgba(14, 14, 14, 0.3);
    }
`;

const Btn = styled(Icon)`
    position: absolute;
    z-index: 10;
    top: 50%;
    transform: translateY(-50%);
    width: 50px;
    height: 50px;
    color: ${({ theme }) => theme.colors.background};
`;

export default Slider;
