import React from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../helpers/mediaQueries";

// Components:
import Container from "./grid/Container";

const NotFound: React.SFC = () => {
    return (
        <Container>
            <Layout>
                <Title>404</Title>
                <Message>Page Not Found.</Message>
            </Layout>
        </Container>
    );
};

// Styles for Styled Components:
const Layout = styled.div`
    display: flex;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 10rem);

    ${mediaQueries.laptop(`min-height: calc(100vh - 12rem);`)}
`;

const Title = styled.h1`
    margin: 0;
    text-align: center;
    font-size: 3rem;
    font-weight: 800;

    ${mediaQueries.laptop(`font-size: 4.5rem;`)}
`;

const Message = styled.h2`
    text-align: center;
    font-size: 1.25rem;
    font-weight: 600;
`;

export default NotFound;
