import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    body {
        background-color: ${({ theme }) => theme.colors.background};
        color: ${({ theme }) => theme.colors.primary};
        font-family: Montserrat, sans-serif;
        font-size: 1.125rem;
    }

    #root {
        display: flex;
        flex-flow: column;
        min-height: 100vh;
        width: 100%;
    
    }

    * {
        box-sizing: border-box;
    }
    ul{
        padding: 0 0 0 17px;
    }
    li{
        line-height: 150%;
        margin: 0 0 10px 0;
        ::marker {
            color: ${({ theme }) => theme.colors.gradient};
        }
    }
    strong{
        color: ${({ theme }) => theme.colors.gradient};
        font-weight: 400;
    }
`;

export default GlobalStyles;
