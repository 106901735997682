// Constants:
import { ASSETS_URL } from "../config";

// Types & Interfaces:
import { StoreBadgesAlts, ImageMeta } from "../content";
import { StoreBadge } from "../components/main/StoreBadges";

// export const getProductImagePath = () =>
//     `${ASSETS_URL}/images/product/${lang}/01.jpg`;

export const makeSrcSet = (url: string, sizes: string[]) =>
    sizes
        .map((size: string) => `${url}${size} ${size.split("x", 1)}w`)
        .join(",\n");

export const makeGalleryImages = (sizes: string[], images: ImageMeta[]) =>
    images.map(({ folder, alt }: ImageMeta) => {
        return {
            srcSet: makeSrcSet(
                `${ASSETS_URL}/images/screenshots/${folder}/`,
                sizes,
            ),
            alt: alt,
        };
    });

const convertToAppstoreLang = (lang: "en"): string => {
    switch (lang) {
        case "en":
            return "";
        // case "ja":
        //     return "/jp";
        // case "ko":
        //     return "/kr";
        // case "zh-Hans":
        //     return "/cn";
        // case "zh-Hant":
        //     return "/tw";
        default:
            return "/" + lang;
    }
};

export const makeAppstoreLink = (
    appId: string,
    lang: "en",
    location: string,
    item: string,
): URL => {
    const url = new URL(`https://itunes.apple.com`);
    url.pathname = `${convertToAppstoreLang(lang)}/app/apple-store/id${appId}`;
    url.searchParams.set("mt", "8"); // mt equals 8 means that it is mobile application
    url.searchParams.set("pt", "19853"); // 19853 - it is Vito Technology's AppStore ID it is forever constant
    url.searchParams.set("ct", `ephemeris-landing/${location}:${item}`);
    return url;
};

export const makeGooglePlayLink = (
    appId: string,
    lang: "en",
    location: string,
    item: string,
): URL => {
    const url = new URL(`https://play.google.com/store/apps/details`);
    url.searchParams.set("id", appId);
    if (lang !== "en") {
        url.searchParams.set("hl", lang);
    }
    url.searchParams.set(
        "referrer",
        `utm_source=ephemeris-landing&utm_medium=${item}&utm_campaign=${location}`,
    );
    return url;
};

interface StoreAppIds {
    iosAppId?: string;
    androidAppId?: string;
    huaweiAppId?: string;
}

export const makeStoreBadges = (
    lang: "en",
    { appStoreAlt, googlePlayAlt, appGalleryAlt }: StoreBadgesAlts,
    { iosAppId, androidAppId, huaweiAppId }: StoreAppIds,
    location: string,
    item: string,
): StoreBadge[] => {
    const storeBadges = [];

    if (iosAppId) {
        storeBadges.push({
            storeLink: makeAppstoreLink(
                iosAppId,
                lang,
                location,
                item,
            ).toString(),
            storeImage: `${ASSETS_URL}/images/badges/appstore.svg`,
            storeImageAlt: appStoreAlt,
            id: `appStore_${location}_${item}`,
        });
    }
    if (androidAppId) {
        storeBadges.push({
            storeLink: makeGooglePlayLink(
                androidAppId,
                lang,
                location,
                item,
            ).toString(),
            storeImage: `${ASSETS_URL}/images/badges/googleplay.svg`,
            storeImageAlt: googlePlayAlt,
            id: `googlePlay_${location}_${item}`,
        });
    }
    if (huaweiAppId) {
        storeBadges.push({
            storeLink: `https://appgallery.huawei.com/#/app/${huaweiAppId}`,
            storeImage: `${ASSETS_URL}/images/badges/huaweistore.svg`,
            storeImageAlt: appGalleryAlt,
            id: `appGallery_${location}_${item}`,
        });
    }
    return storeBadges;
};

export const preserveSearch = (search: string, param?: string): string => {
    const searchParams = new URLSearchParams(search);

    const params: string[] = [];

    if (param) {
        params.push(param);
    }
    const ver = searchParams.get("ver");
    if (ver === "2") {
        params.push("ver=2");
    }
    if (params.length > 0) {
        return `?${params.join("&")}`;
    }
    return "";
};
