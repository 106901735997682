import React, { useRef } from "react";
import styled from "styled-components";

interface Props {
    isActive: boolean;
    onRightHandler: any;
    onLeftHandler: any;
}

const Slide: React.FC<Props> = ({
    isActive,
    onRightHandler,
    onLeftHandler,
    children,
}) => {
    const startingX = useRef<number>(0);
    const endingX = useRef<number>(0);
    const startingY = useRef<number>(0);
    const endingY = useRef<number>(0);

    if (typeof window !== "undefined") {
        var body = document.querySelector("body");
    }

    const calculateSwipeAngle = () => {
        const differenceX = Math.abs(startingX.current - endingX.current);
        const differenceY = Math.abs(startingY.current - endingY.current);
        const swipeAngle =
            (Math.atan(differenceY / differenceX) * 180) / Math.PI;
        return swipeAngle;
    };
    return (
        <SlideWrapper
            isActive={isActive}
            onTouchStart={e => {
                startingX.current = e.changedTouches[0].clientX;
                startingY.current = e.changedTouches[0].clientY;
            }}
            onTouchMove={() => {
                if (calculateSwipeAngle() < 45) {
                    body!.ontouchmove = () => {
                        return false;
                    };
                } else {
                    body!.ontouchmove = () => {
                        return true;
                    };
                }
            }}
            onTouchEnd={e => {
                endingX.current = e.changedTouches[0].clientX;
                endingY.current = e.changedTouches[0].clientY;

                if (
                    startingX.current - endingX.current > 0 &&
                    calculateSwipeAngle() < 45
                ) {
                    onRightHandler();
                } else if (
                    startingX.current - endingX.current < 0 &&
                    calculateSwipeAngle() < 45
                ) {
                    onLeftHandler();
                }

                body!.ontouchmove = () => {
                    return true;
                };
            }}
        >
            {children}
        </SlideWrapper>
    );
};

interface StatusProps {
    isActive: boolean;
}
const SlideWrapper = styled.div<StatusProps>`
    width: 100%;
    opacity: ${({ isActive }) => (isActive ? 1 : 0)};
    transition: all 0.4s;
    position: absolute;
`;

export default Slide;
