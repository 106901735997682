import React from "react";
import { Helmet } from "react-helmet";

const HtmlInit: React.SFC = () => (
    <Helmet>
        <meta charSet="utf-8" />
        <meta
            name="viewport"
            content="width=device-width, initial-scale=1, shrink-to-fit=no"
        />
        <link rel="shortcut icon" href="/favicon.ico" />
        <link rel="manifest" href="/manifest.json" />
        <meta name="theme-color" content="#21170C" />
    </Helmet>
);

export default HtmlInit;
