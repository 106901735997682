import React, { useContext } from "react";
import { Content } from "./AppRoot";
import { DOMAIN_URL, APPLE_APP_ID, ASSETS_URL } from "../config";
import HtmlTitle from "./helmet/HtmlTitle";
import HtmlDescription from "./helmet/HtmlDescription";
import HtmlLang from "./helmet/HtmlLang";
import HtmlCanonicalLink from "./helmet/HtmlCanonicalLink";
import HtmlSmartBanner from "./helmet/HtmlSmartBanner";
// import HtmlFacebookSDK from "./helmet/HtmlFacebookSDK";
import HtmlOpenGraph from "./helmet/HtmlOpenGraph";
import { PREVIEW_IMAGE } from "../content";
import HtmlTwitter from "./helmet/HtmlTwitter";

const MetaTags: React.FC = () => {
    const {
        meta: { description, title },
    } = useContext(Content);

    const canonicalLink = DOMAIN_URL;

    const imageUrl = `${ASSETS_URL}/images/${PREVIEW_IMAGE}`;
    return (
        <>
            <HtmlTitle value={title} />
            <HtmlDescription value={description} />
            <HtmlLang lang={"en"} />
            <HtmlCanonicalLink value={canonicalLink} />
            <HtmlSmartBanner appId={APPLE_APP_ID} />
            {/* <HtmlFacebookSDK /> */}
            <HtmlOpenGraph
                title={title}
                description={description}
                openGraphImageSize={"1200x630"}
                url={canonicalLink}
                openGraphImageURL={imageUrl}
            />
            <HtmlTwitter
                title={title}
                description={description}
                openGraphTwitterImageURL={imageUrl}
            />
        </>
    );
};

export default MetaTags;
