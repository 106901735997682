import contentData from "./content.json";

export const PRIVACY_LINK = "https://vitotechnology.com/privacy-policy.html";

export const TERMS_LINK = "https://vitotechnology.com/terms-of-use.html";

export const PREVIEW_IMAGE = "ephemeris-preview.png";

export const content: Content = contentData;

interface Content {
    dict: {
        [key: string]: string;
    };
    footer: {
        privacy: string;
        terms: string;
    };
    meta: {
        description: string;
        title: string;
    };
    storeBadges: StoreBadgesAlts;
    productPage: {
        introTitle: string;
        introBackgroundImage: string;
        introSubtitle: string;
        mainAboutTitle: string;
        mainAboutText: string;
        mainAbilitiesTitle: string;
        mainAbilitiesText: string;
        mainFeaturesTitle: string;
        slides: FeaturesSlides[];
    };
    gallerySection: {
        title: string;
        subtitle: string;
    };
    galleryImagesMeta: ImageMeta[];
}

export interface ImageMeta {
    folder: string;
    alt: string;
}

export interface StoreBadgesAlts {
    appStoreAlt: string;
    googlePlayAlt: string;
    appGalleryAlt: string;
}

export interface FeaturesSlides {
    id?: string;
    productId?: string;
    sourceUrl: string;
    name: string;
    copyright?: {
        author: string | null;
        url: string;
    };
    description?: string;
}
