import React, { useContext } from "react";
import styled from "styled-components";

// Helpers:
import mediaQueries from "../../helpers/mediaQueries";

// Components:
import Container from "../grid/Container";
import { Content } from "../AppRoot";
import { PRIVACY_LINK, TERMS_LINK } from "../../content";

const Footer: React.SFC = () => {
    const {
        footer: { privacy, terms },
    } = useContext(Content);

    return (
        <FooterSection>
            <Container rows={3}>
                <LinksContainer>
                    <Link id="footer-privacy-link" href={PRIVACY_LINK}>
                        {privacy}
                    </Link>
                    <Link id="footer-terms-link" href={TERMS_LINK}>
                        {terms}
                    </Link>
                </LinksContainer>
                <Copyright>
                    {`© ${new Date().getFullYear()} `}
                    <ExternalLink href="https://www.vitotechnology.com">
                        Vito Technology, Inc.
                    </ExternalLink>
                </Copyright>
            </Container>
        </FooterSection>
    );
};

// Styles for Styled Components:
const FooterSection = styled.footer`
    padding: 1rem 0;
    z-index: 100;
`;

const LinksContainer = styled.div`
    justify-content: center;
    display: flex;
    flex-wrap: wrap;
    grid-column: 1 / span 12;
    grid-row: 2 / span 1;
`;

const Link = styled.a`
    margin: 0.5rem 0.5rem;
    color: ${({ theme }) => theme.colors.gradient};
    font-size: 0.75rem;
    text-align: center;
    text-decoration: none;
    ${mediaQueries.laptop(`&:hover { text-decoration: underline; }`)}
`;

const Copyright = styled.div`
    margin: 0.5rem 0;
    color: #acacac;
    font-size: 0.75rem;
    text-align: center;

    grid-column: 1 / span 12;
    grid-row: 3 / span 1;
`;

const ExternalLink = styled.a`
    color: #acacac;
    text-decoration: none;
    ${mediaQueries.laptop(`&:hover { text-decoration: underline; }`)}
`;

export default Footer;
